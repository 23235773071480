<template>
    <ClientOnly>
        <AtomsGlobalOverlay :isVisible="props.isOpen" :vertical-align="props.verticalAlign" :customZIndex="2002" @click="close()" :custom-class="props.overlayClass" :title="props.hideCloseIcon ? '' : $t('Kliknutím zavřete')">
            <OrganismsDialogsMessage v-if="isMessageContainer" class="!top-0 z-[10000]" :show="message.show" :icon="message.icon" :text="message.text" :button-text="message.buttonText" :type="message.type" @hide="hideMessage" />
            <div class="relative z-[2005] max-h-full" :class="[{ 'overflow-auto': !props.overflow }, props.overflow, props.class]" title="" data-pagearea="modal">
                <AtomsContainersCornerBorders class="bg-neutral-50 p-6 desktop:!p-12" inner-class="outline-secondary-500">
                    <slot></slot>
                </AtomsContainersCornerBorders>
                <AtomsImagesIcon v-if="!props.hideCloseIcon" icon="cross" size="xs" class="absolute right-8 top-8 cursor-pointer transition-all duration-500 hover:rotate-[180deg]" @click="close()" :title="$t('Zavřít')"></AtomsImagesIcon>
            </div>
        </AtomsGlobalOverlay>
    </ClientOnly>
</template>
<script setup>

const props = defineProps({
    isOpen: Boolean,
    class: String,
    overlayClass: String,
    verticalAlign: String,
    overflow: String,
    hideCloseIcon: Boolean,
    isMessageContainer: Boolean
})

const emit = defineEmits(['close']);

const close = () => {
    if (!props.hideCloseIcon && props.isOpen) {
        emit('close');
    }
}

const hideMessage = () => useMessage().hide();

const message = useMessage().message;

onMounted(() => {
    window.addEventListener('keydown', handleEscapePress);
});

onUnmounted(() => {
    window.removeEventListener('keydown', handleEscapePress);
});

const handleEscapePress = (event) => {
    if (event.key === 'Escape') {
        close();
    }
}

</script>